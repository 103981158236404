import * as React from 'react';
import { FormGroup, FormControlLabel, Checkbox, Button, RadioGroup, FormLabel, Radio, TextField } from '@mui/material';
import { validateCNPJ, validateCPF } from '../utils/masks';
import { Person } from '@mui/icons-material';
import api from '../services/api.js'
import LinearProgress from '@mui/material/LinearProgress';

const Serasa = () => {

    const [person, setPerson] = React.useState('fisica')
    const [cpf, setCpf] = React.useState('')
    const [phones, setPhones] = React.useState([])
    const [emails, setEmails] = React.useState([])
    const [verifiedEmails, setVerifiedEmails] = React.useState([])
    const [companies, setCompanies] = React.useState([])
    const [price, setPrice] = React.useState([])
    const [openProgress, setOpenProgress] = React.useState(false)

    const [state, setState] = React.useState({
        phone: false,
        verifiedEmail: false,
        email: false,
        socio: false,
    });

    const changePerson = (event) => {
        setPerson(event.target.value)
    }

    const changeCPF = (event) => {
        var value = event.target.value;
        // console.log('valid: ', valid)
        value = value.replace(/\D/g, '')

        var formattedValue = null

        if(person == "juridica")
        {
            if (value.length > 14) {
              value = value.substring(0, 14);
            }
            formattedValue = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }
        else
        {
            if (value.length > 11) {
                value = value.substring(0, 11);
            }
            formattedValue = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        }

        setCpf(formattedValue)
    }
    
    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    async function search() {
        setOpenProgress(true)
        var error = person == "juridica" ? !validateCNPJ(cpf):!validateCPF(cpf)
        console.log('person: ', person, 'cpf: ', cpf, 'state: ', state)
        if(error)
        {
            alert('CPF/CNPJ inválido')
        }
        else
        {
            var cpfCnpj = person == "juridica" ? "cnpj=" : "cpf="
            var url = 'contact?' + cpfCnpj + cpf + "&telefone=" + (state.phone ? "sim":"nao") + "&email=" + (state.email ? "sim":"nao") + "&emailValidado=" + (state.verifiedEmail ? "sim":"nao") + "&socioEmpresa=" + (state.socio ? "sim":"nao")
            console.log("url: ", url)
            const res = await api.get(url)
            setOpenProgress(false)
            setPhones(res.data.telefones == null ? []:res.data.telefones)
            setEmails(res.data.email == null ? []:res.data.email)
            setVerifiedEmails(res.data.emailValidado == null ? []:res.data.emailValidado)
            setCompanies(res.data.sociosEmpresa == null ? []:res.data.sociosEmpresa)
            setPrice(res.data.preco)
            console.log('res: ', res.data)
        }
    }

    return (
        <div>
            {openProgress ?
                <LinearProgress style={{color: "#D607BA", marginTop: "2%", marginBottom: "2%"}} />
                :null
            }
            <div>
                <div align="center" style={{
                    'float': "left",
                    "width": "40%",
                    'marginLeft': '5%',
                    'marginTop': '5%'
                }}>
                    <FormLabel style={{color: "#000000", marginBottom:"4%"}}>
                        <strong>
                            Esse propritário é uma pessoa física ou jurídica?
                        </strong>
                    </FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={person || ''}
                    >
                        <div>
                            <FormControlLabel onChange={changePerson} value={person} style={{fontSize: "2em"}}
                            value="fisica" control={<Radio style={{color: "#D607BA"}} />} 
                            label="Física" />
                            <FormControlLabel onChange={changePerson} value={person} style={{fontSize: "2em"}}
                            value="juridica" control={<Radio style={{color: "#D607BA"}} />}
                            label="Jurídica" />
                        </div>
                    </RadioGroup>
                    
                    <div>
                        <TextField
                            error={person == "juridica" ? !validateCNPJ(cpf):!validateCPF(cpf)}
                            id="standard"
                            helperText={person == "juridica" ? "CNPJ":"CPF"}
                            type="text"
                            label={person == "juridica" ? "CNPJ":"CPF"}
                            fullWidth
                            value={cpf || ''}
                            onChange={changeCPF}
                            variant="outlined"
                        />
                </div>
                <FormGroup>
                    <FormControlLabel control={<Checkbox onChange={handleChange} name="phone" />} label="Telefone" />
                    <FormControlLabel control={<Checkbox onChange={handleChange} name="verifiedEmail" />} label="E-mail verificado" />
                    <FormControlLabel control={<Checkbox onChange={handleChange} name="email" />} label="E-mail" />
                    <FormControlLabel control={<Checkbox onChange={handleChange} name="socio" />} label="Empresas que é sócio" />
                </FormGroup>
                <Button size="large" onClick={search} variant="contained" style={{background: "#D607BA"}}>
                    <strong>Consultar</strong>
                </Button>
            </div>
            <div align="left" style={{
                'float': "left",
                "width": "40%",
                'margin': '5%'
            }}>
                <strong>Preço da consulta: R$ </strong>{(price).toString().replace('.',',')}
                <br/>
                <strong>Telefones:</strong>
                    {phones.length > 0 ?
                        <div>
                            {phones.map(phone => {
                                return phone.slice(0, 2) + ' ' + phone.slice(2) + ', '
                            })}
                        </div>
                        :
                        <div>
                            Nenhum telefone encontrado
                        </div>
                    }
                <br/>

                <strong>Emails:</strong>
                {emails.length > 0 ?
                    <div>
                        {emails.map(email => {
                            return email +  ', '
                        })}
                    </div>
                    :
                    <div>
                        Nenhum e-mail encontrado
                    </div>
                }
                <br/>
                <strong>Emails validados:</strong>
                {verifiedEmails.length > 0 ?
                    <div>
                        {verifiedEmails.map(email => {
                            return email +  ', '
                        })}
                    </div>
                    :
                    <div>
                        Nenhum e-mail validado encontrado
                    </div>
                }
                <br/>
                <strong>Empresas:</strong>
                <br/>
                {companies.length > 0 ?
                    <table border="1">
                        <tbody>
                            <tr>
                                <th>CNPJ</th>
                                <th>RAZÃO SOCIAL</th>
                                <th>PERCENTUAL SOCIO</th>
                            </tr>
                            {companies.map(company => {
                                return (
                                    <tr key={company.razaoSocial}>
                                        <td align="center">{company.cnpj}</td>
                                        <td align="center">{company.razaoSocial}</td>
                                        <td align="center">{company.participacaoSocio}</td>
                                </tr>
                                )
                            })}
                        </tbody>
                    </table>:
                    <div>
                        Nenhuma empresa encontrada
                    </div>
                }
                </div>
            </div>
        </div>
    )
}
export default Serasa;